import React from "react";
import Header from "./Header.js";
import MessagePopUp from "./MessagePopUp.js";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CookieConsent from "react-cookie-consent";

import "./App.css";

import axios from "axios";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme.js";

function App() {
  const MESSAGE_MINIMUM_LENGTH = 10;
  const MESSAGE_MAXIMUM_LENGTH = 280;
  const MESSAGE_WRONG_LENGTH_TEXT =
    "Craft your message within " +
    MESSAGE_MINIMUM_LENGTH +
    " to " +
    MESSAGE_MAXIMUM_LENGTH +
    " characters.";
  const MESSAGE_POPUP_USER_TITLE = "Previous person says...";
  const MESSAGE_POPUP_AI_TITLE = "No new message - got a response from AI..."
  const TEXT_BOX_LABEL = "Pen your thoughts...";

  const SEO_TITLE = "Message Flip: Uncover the Last Hidden Message";
  const META_DESCRIPTION = `Message Flip is a powerful app that allows you to reveal the last message someone posted.
    Submit your message and discover what others have shared.
    Start uncovering hidden messages at msgflip.io now!`;

  const [messagePopupOpen, setPopUpOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [popUpTitle, setPopUpTitle] = React.useState("");
  const [textBoxValue, setTextBoxValue] = React.useState("");
  const [textBoxErrorState, setTextBoxErrorState] = React.useState(false);

  
  const INVALID_MESSAGE = "INVALID_MESSAGE";
  const USER_MESSAGE = "USER_MESSAGE";
  const AI_MESSAGE = "AI_MESSAGE";

  const handleSubmitMessage = () => {
    if (
      textBoxValue.length < MESSAGE_MINIMUM_LENGTH ||
      textBoxValue.length > MESSAGE_MAXIMUM_LENGTH
    ) {
      handleTextFieldErrorState(true, MESSAGE_WRONG_LENGTH_TEXT);
      return;
    }

    const messageToSend = JSON.stringify({
      messageBody: textBoxValue,
    });

    axios
      .put(window._env_.API_URL + "message", messageToSend, {
        withCredentials: true,
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.messageType === AI_MESSAGE || res.data.messageType === USER_MESSAGE) {
          handlePopupMessage(res.data.message.messageBody, res.data.messageType);
        } else if (res.data.messageType === INVALID_MESSAGE) {
          handleTextFieldErrorState(true, res.data.message.messageBody);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleCloseMessagePopup = () => {
    setPopUpOpen(false);
  };

  const handleTextBoxUpdate = (event) => {
    setTextBoxValue(event.target.value);
  };

  const handleTextFieldErrorState = (errorState, errorMessage) => {
    if (errorState === true) {
      setTextBoxErrorState({ inError: true, errorText: errorMessage });
    } else {
      setTextBoxErrorState({ inError: false, errorText: "" });
    }
  };

  const handlePopupMessage = (message, messageType) => {
    setTextBoxValue("");
    handleTextFieldErrorState(false, "");
    setMessage(message);
    if(messageType === AI_MESSAGE) {
      setPopUpTitle(MESSAGE_POPUP_AI_TITLE);
    } else if (messageType === USER_MESSAGE) {
      setPopUpTitle(MESSAGE_POPUP_USER_TITLE);
    }
    setPopUpOpen(true);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmitMessage();
    }
  };

  return (
  <HelmetProvider>  
  <ThemeProvider theme={theme}>
  
      <div className="App">
    
      
        <Helmet>
          <title>{SEO_TITLE}</title>
          <meta name="description" content={META_DESCRIPTION} />
        </Helmet>
      
        {/* Full screen grid, centers everything vertically and horizontally */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "300vh" }}
        >
          <Grid item style={{ marginTop: "-50px" }}>
            <Header />
          </Grid>

          {/* Content Grid */}

          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              {/* TextField Grid */}
              <Grid item style={{ marginTop: "-15px" }}>
                {/* <TextField label="Outlined secondary" color="secondary" focused /> */}
                {/* <TextField label="Filled success" variant="filled" color="success" focused /> */}

                <TextField
                  className="textField"
                  value={textBoxValue}
                  error={textBoxErrorState.inError}
                  helperText={textBoxErrorState.errorText}
                  id="filled-basic"
                  label={TEXT_BOX_LABEL}
                  variant="filled"
                  fullWidth={false}
                  onChange={handleTextBoxUpdate}
                  onKeyPress={handleKeypress}
                  style={{ marginBottom: "1px" }}
                />
              </Grid>

              {/* Button Grid */}
              <Grid item style={{ marginTop: "-15px" }}>
                <Button
                  className="button"
                  variant="contained"
                  fullWidth
                  onClick={handleSubmitMessage}
                  size="large" // Enlarge Button
                  style={{
                    backgroundColor: "#4d688f",
                    color: "#f7f2e6",
                    marginTop: "0px",
                  }}
                >
                  Reveal a message <span role="img" aria-label="Bottle"></span>
                </Button>
                <MessagePopUp
                  popClose={handleCloseMessagePopup}
                  open={messagePopupOpen}
                  title={popUpTitle}
                  message={message}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CookieConsent
          style={{
            fontSize: "13px",
            textShadow: "none",
            margin: "0 auto", // Center the button horizontally
            display: "block", // Ensure the button is a block-level element to respect margin auto
            paddingTop: "5px",
            paddingBottom: "5px",
            lineHeight: "1.2",
          }}
          buttonStyle={{
            fontSize: "12px",
            margin: "0 auto", // Attempt to center the button
            marginTop: "-10px",
            display: "block",
            backgroundColor: "#4d688f", // Set the button background color to blue
            color: "#f7f2e6", // Set the button text color to white for better readability
            borderRadius: "20px",
          }}
        >
          <div style={{ padding: "1px 1px", marginTop: "-10px" }}>
            Functional cookies needed for the magic.
          </div>
        </CookieConsent>
      </div>{" "}
      
    </ThemeProvider>
  </HelmetProvider>
  );
  
}

export default App;
