import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const dialogColor = "White"; // Colour of the diaglog box
const dialogContent = "#4d688f"; // Colour of the dialog text

const MessagePopUp = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.popClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ bgcolor: dialogColor, color: dialogContent, textShadow: "none" }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent
        sx={{ bgcolor: dialogColor, color: dialogContent, textShadow: "none" }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{ bgcolor: dialogColor, color: dialogContent }}
        >
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ bgcolor: dialogColor, color: dialogContent }}>
        <Button onClick={props.popClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessagePopUp;
